<template>
  <div class="chart-node" :class="`chart-node--${type}`">
    <div class="chart-node__title">
      {{ title | nodeTitle }}
    </div>

    <div
      v-if="description"
      class="chart-node__description"
      v-html="description"
    />

    <button v-if="url" @click="onPreview" class="button">Preview</button>
  </div>
</template>

<script>
export default {
  name: "FlowChartNode",
  props: ["type", "url", "node", "title", "description"],
  filters: {
    nodeTitle(value) {
      return value.replaceAll("__", " ");
    },
  },
  data() {
    return {};
  },
  methods: {
    onPreview() {
      window.open(this.url, "_blank");
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";
@import "@/scss/button";

.chart-node {
  border: 1px solid $gray;
  border-radius: 4px;
  padding: 1rem;
  position: relative;
  z-index: 10;

  &__title,
  &__description {
    max-width: 200px;
    min-width: 125px;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
  }

  &__description {
    font-size: 12px;
    margin-top: 0.5rem;
  }

  .button {
    font-size: 12px;
    margin-top: 0.5rem;
    padding: 0.25rem 0.5rem;
  }

  &--header {
    .chart-node__title {
      font-size: 18px;
      max-width: none;
    }
  }

  &--lander {
    background-color: $light-blue-tint3;
  }

  &--email {
    background-color: $fade-green;
  }

  &--resource {
    background-color: $fade-red;
  }
}
</style>