<template>
  <div class="campaign-overview">
    <loading :active="loading" :fullscreen="true" />

    <div class="campaign-overview__hero">
      <div class="container">
        <h2 v-if="campaign">{{ campaign.name }} | Campaign Asset Map</h2>
      </div>
    </div>

    <div class="campaign-overview__chart">
      <flowy class="q-mx-auto" :nodes="nodes"></flowy>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import FlowChartNode from "@/components/charts/FlowChartNode";
import "@hipsjs/flowy-vue/dist/lib/flowy-vue.css";

export default {
  name: "CampaignOverview",
  components: {
    Loading,
  },
  data() {
    return {};
  },
  computed: {
    baseURL() {
      return process.env.VUE_APP_REMOTE_FRONTEND_BASE;
    },
    campaign() {
      return this.$store.getters["engage/campaign"];
    },
    loading() {
      return this.$store.getters["engage/loading"];
    },
    nodes() {
      const nodes = [
        {
          id: "campaign",
          parentId: -1,
          nodeComponent: FlowChartNode,
          data: {
            type: "header",
            title: this.campaign.name,
            description: null,
          },
        },
        {
          id: "landers",
          parentId: "campaign",
          nodeComponent: FlowChartNode,
          data: {
            type: "lander",
            title: "Landing Pages",
            description: "Pages served directly from ads",
          },
        },
        {
          id: "emails",
          parentId: "campaign",
          nodeComponent: FlowChartNode,
          data: {
            type: "email",
            title: "Emails",
            description:
              "Follow-up emails triggered by events and dispositions",
          },
        },
        {
          id: "resources",
          parentId: "campaign",
          nodeComponent: FlowChartNode,
          data: {
            type: "resource",
            title: "Resources",
            description: "Articles, infographics, and other related resources",
          },
        },
      ];

      this.campaign.views.forEach((view) => {
        if (view.type === "lander") {
          let view_url = `${this.baseURL}/${this.campaign.slug}/${view.slug}?token=${view.preview_token}`;
          if (view.template != "article") {
            nodes.push({
              id: "view-" + view.id,
              parentId: "landers",
              nodeComponent: FlowChartNode,
              data: {
                type: "lander",
                title: view.name,
                description: null,
                url: view_url,
              },
            });
          }

          if (view.template == "article") {
            nodes.push({
              id: "view-" + view.id,
              parentId: "resources",
              nodeComponent: FlowChartNode,
              data: {
                type: "resource",
                title: view.name,
                description: null,
                url: view_url,
              },
            });
          }
        }

        if (view.type === "email") {
          let view_url = `${process.env.VUE_APP_EMAIL_API.replace(
            "/api",
            ""
          )}/email/${view.id}/default`;
          nodes.push({
            id: "view-" + view.id,
            parentId: "emails",
            nodeComponent: FlowChartNode,
            data: {
              type: "email",
              title: view.name,
              description: null,
              url: view_url,
            },
          });

          if (view.events.length) {
            nodes.push({
              id: "view-" + view.id + "-events",
              parentId: "view-" + view.id,
              nodeComponent: FlowChartNode,
              data: {
                type: "email",
                title: "Dispositions",
                description: view.events
                  .map((event) => {
                    return event.external_name;
                  })
                  .join("<br />"),
                url: null,
              },
            });
          }
        }
      });

      this.campaign.ads.forEach((ad) => {
        nodes.push({
          id: "ad-" + ad.id,
          parentId: "view-" + ad.view_id,
          nodeComponent: FlowChartNode,
          data: {
            url: ad.preview_url,
            type: "lander",
            title: ad.name,
            description: "Source: " + ad.source,
          },
        });
      });

      return nodes;
    },
  },
  methods: {
    loadCampaign() {
      // Get the campaign id
      const id = this.$route.params.id;
      this.$store.dispatch("engage/getCampaign", { campaign_id: id });
    },
  },
  mounted() {
    this.loadCampaign();
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";
@import "@/scss/button";

.campaign-overview {
  padding-bottom: 0 !important;

  &__hero {
    height: 256px;
    background: $light-blue;
    background: linear-gradient(315deg, $light-blue 0%, $dark-blue 100%);
    margin-top: -2rem;

    .container {
      height: calc(100% - 8rem);
      display: flex;
      align-items: center;
      color: #fff;

      h2 {
        margin-bottom: 0 !important;
        font-size: 1.5rem !important;
        font-weight: 600;
      }
    }
  }

  &__chart {
    background-color: #fff;
    margin-top: -8rem;
    padding: 2rem;
  }
}
</style>
